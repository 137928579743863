import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useClassName } from 'common/hooks';
import { Modal, Button } from 'common/lazy';
import './ovewritten-operation-hours.less';
import { ContentfulRichText } from '../../../common/components';

const OvewrittenOperationHours = ({ ovewrittenOperationHours }) => {
  const [openModal, setOpenModal] = useState(false);
  const className = useClassName('OvewrittenOperationHours');

  return (
    <>
      <button
        className={className('container')}
        onClick={() => setOpenModal(true)}>
        View Open Hours
      </button>
      <Modal
        basic
        open={openModal}
        size="small"
        className={className('modal')}
        onClose={() => setOpenModal(false)}>
        <div className={className('content')}>
          <p className={className('title')}>Operating hours</p>
          <ContentfulRichText field={ovewrittenOperationHours} />
          <Button
            onClick={() => setOpenModal(false)}
            inverted
            className={className('close-btn')}>
            Close
          </Button>
        </div>
      </Modal>
    </>
  );
};

OvewrittenOperationHours.propTypes = {
  ovewrittenOperationHours: PropTypes.object,
};

export default OvewrittenOperationHours;
