import { inject } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ContentfulMarkdown } from 'common/components';
import { useClassName } from 'common/hooks';
import { VenueHoursCustom } from '../../Custom/Hours';
import useCustomStyle from '../../../hooks';
import { CircleParking } from 'public/icons';

import './avenue-details.less';

export default inject('venues')(function AvenueDetails({ venues, component }) {
  const classNames = useClassName('CLPAvenueDetails');
  const [venue, setVenue] = useState(null);
  const params = useParams();
  const [, style] = useCustomStyle(component.fields.settings);

  const title = style['--title'];
  const description = style['--description'];
  const directions = style['--directions'];

  const titleWords = title ? title.split(' ') : [];
  const firstWord = titleWords[0] || '';
  const restWords = titleWords.slice(1).join(' ') || '';

  useEffect(() => {
    venues.fetchItemBySlug(params.slug).then((venue) => {
      setVenue(venue);
    });
  }, []);

  if (!venue) return null;

  return (
    <div className={classNames('container')}>
      <div className={classNames('title')}>
        <span className={classNames('title-first-word')}>{firstWord}</span>
        <span className={classNames('title-rest')}>{restWords}</span>
      </div>
      <div className={classNames('description-and-hours')}>
        <div className={classNames('description')}>{description}</div>

        <div className={classNames('info')}>
          <VenueHoursCustom venue={venue} />
          <div className={classNames('directions')}>
            <CircleParking />
            <ContentfulMarkdown
              className={classNames('directions-text')}
              field={{ 'en-US': directions }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
