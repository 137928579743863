import React, { useState, useEffect } from 'react';

import { useClassName, useWindowSize } from 'common/hooks';
import { SimpleCalendar } from 'public/components/Calendar';
import { ClockIcon, ChevronDownIcon } from 'public/icons';
import { Dropdown } from 'public/components/Dropdown';
import { BottomSheet } from 'public/components/BottomSheet';

import './hours.less';

function to12h(hour, minute) {
  const timeString = `${hour.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')}:00`;
  return new Date('1970-01-01T' + timeString + 'Z')
    .toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric',
    })
    .replace(':00', '');
}

function formatHours(hours) {
  return `${to12h(hours.openHour, hours.openMinute)} - ${to12h(
    hours.closeHour,
    hours.closeMinute
  )}`;
}

function isClosedHours(hours) {
  if (hours.isClosed) return true;
  return (
    !hours.openHour &&
    !hours.openMinute &&
    !hours.closeHour &&
    !hours.closeMinute
  );
}

function getHoursForDate(venue, date) {
  const dateString = date.toISOString().split('T')[0];
  if (venue.openingHours.irregular) {
    const irregular = venue.openingHours.irregular.find(
      (entry) => entry.date === dateString
    );
    if (irregular) return irregular;
  }
  const weekday = date
    .toLocaleDateString('en-US', { weekday: 'long' })
    .toLowerCase();
  return venue.openingHours.regular.find(
    (entry) => entry.weekday.toLowerCase() === weekday
  );
}

function RegularHours({ venue }) {
  const classNames = useClassName('RestaurantHours');
  return (
    <div className={classNames('regular')}>
      {venue.openingHours.regular.map((regular) => (
        <div key={regular.weekday}>
          <span>
            {regular.weekday.substring(0, 1).toUpperCase()}
            {regular.weekday.substring(1, 3)}
          </span>
          <span>
            {isClosedHours(regular) ? 'Closed' : formatHours(regular)}
          </span>
        </div>
      ))}
    </div>
  );
}

export function VenueHours({ venue }) {
  const classNames = useClassName('RestaurantHours');
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile } = useWindowSize();

  if (!venue?.openingHours) return null;
  const isClosed =
    venue.openingHours.temporarilyClosed ||
    isClosedHours(venue.openingHours.projectedDays[0]);

  return (
    <div className={classNames('wrapper')}>
      <ClockIcon />
      <div className={classNames(['container', isClosed && 'closed'])}>
        <div>
          <span
            className={classNames(['label', isClosed ? 'closed' : 'opened'])}>
            {isClosed ? 'Closed' : 'Open'}
          </span>
        </div>
        {!isClosed && <span>&#8226;</span>}
        <div>
          {!isClosed && (
            <span className={classNames('label')}>
              {formatHours(venue.openingHours.projectedDays[0])}
            </span>
          )}
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className={classNames('arrow')}>
            <ChevronDownIcon />
          </div>
        </div>
        {isMobile ? (
          <BottomSheet
            isOpen={isExpanded}
            onClose={() => setIsExpanded(!isExpanded)}
            title="Hours of Operation"
            content={<RegularHours venue={venue} />}
          />
        ) : (
          <Dropdown
            isOpen={isExpanded}
            onClose={() => setIsExpanded(!isExpanded)}
            content={<RegularHours venue={venue} />}
          />
        )}
      </div>
    </div>
  );
}

function RegularHoursWithCustomDate({
  venue,
  selectedDate,
  setSelectedDate,
  onApply,
  currentView,
  targetView,
  setCurrentView,
  setTargetView,
  isMobile,
}) {
  const classNames = useClassName('HoursWithCustomDate');
  const [isFading, setIsFading] = useState(false);
  const transitionDuration = 300;

  const handleDateSelect = (date) => {
    if (date) {
      setSelectedDate(new Date(date));
    }
  };

  const switchView = (newView) => {
    if (newView !== currentView) {
      setTargetView(newView);
      setIsFading(true);

      if (newView === 'thisWeek') {
        setSelectedDate(new Date());
      }
    }
  };

  useEffect(() => {
    if (isFading) {
      const timeout = setTimeout(() => {
        setCurrentView(targetView);
        setIsFading(false);
      }, transitionDuration);
      return () => clearTimeout(timeout);
    }
  }, [isFading, targetView]);

  const isDateToday =
    new Date().toISOString().split('T')[0] ===
    selectedDate.toISOString().split('T')[0];

  return (
    <div className={classNames('container')}>
      <div className={classNames('header')}>Hours of Operations</div>
      <div className={classNames('content')}>
        <div className={classNames('buttons')}>
          <button
            className={classNames(
              'button',
              currentView === 'thisWeek' && 'active'
            )}
            onClick={() => switchView('thisWeek')}>
            This Week
          </button>
          <button
            className={classNames(
              'button',
              currentView === 'customDate' && 'active'
            )}
            onClick={() => switchView('customDate')}>
            Custom Date
          </button>
        </div>
        <div
          className={classNames(
            'hours-and-picker',
            isFading ? 'fade-out' : 'fade-in'
          )}
          style={{ transition: `opacity ${transitionDuration}ms ease-in-out` }}>
          {currentView === 'thisWeek' ? (
            <div className={classNames('regular')}>
              {venue.openingHours.regular.map((regular) => (
                <div key={regular.weekday}>
                  <span>
                    {regular.weekday.substring(0, 1).toUpperCase()}
                    {regular.weekday.substring(1)}
                  </span>
                  <span>
                    {isClosedHours(regular) ? 'Closed' : formatHours(regular)}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <SimpleCalendar
              initialDate={selectedDate.toISOString()}
              updateSelectedDate={handleDateSelect}
              isDateUnavailable={() => false}
            />
          )}
        </div>
      </div>
      {isMobile && (
        <div className={classNames('footer')}>
          <button
            className={classNames(
              currentView === 'customDate' ? 'apply-button' : 'close-button',
              !isDateToday && 'different-date'
            )}
            onClick={onApply}
            disabled={currentView === 'customDate' && isDateToday}>
            {currentView === 'customDate' ? 'Apply' : 'Close'}
          </button>
        </div>
      )}
    </div>
  );
}

export function VenueHoursCustom({ venue }) {
  const classNames = useClassName('RestaurantHours');
  const { isMobile } = useWindowSize();
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentView, setCurrentView] = useState('thisWeek');
  const [targetView, setTargetView] = useState('thisWeek');

  if (!venue?.openingHours) return null;

  const hoursForDisplay = getHoursForDate(venue, selectedDate);
  const isClosed =
    venue.openingHours.temporarilyClosed || isClosedHours(hoursForDisplay);
  const formattedDate = selectedDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  });

  const content = (
    <RegularHoursWithCustomDate
      venue={venue}
      selectedDate={selectedDate}
      setSelectedDate={setSelectedDate}
      onApply={() => setIsExpanded(false)}
      currentView={currentView}
      targetView={targetView}
      setCurrentView={setCurrentView}
      setTargetView={setTargetView}
      isMobile={isMobile}
    />
  );

  return (
    <div className={classNames('wrapper')}>
      <ClockIcon />
      <div className={classNames('container')}>
        <span className={classNames(['label', isClosed ? 'closed' : 'opened'])}>
          {isClosed ? 'Closed' : 'Open'}
        </span>
        <span>&#8226;</span>
        <div>
          <span className={classNames('date')}>
            {!isClosed ? `${formattedDate},` : formattedDate}
          </span>
          {!isClosed && (
            <span className={classNames('hours-label')}>
              {isClosed ? 'Closed' : formatHours(hoursForDisplay)}
            </span>
          )}
          <div
            onClick={() => setIsExpanded(!isExpanded)}
            className={classNames('arrow')}>
            <ChevronDownIcon />
          </div>
        </div>
        {isMobile ? (
          <BottomSheet
            isOpen={isExpanded}
            onClose={() => setIsExpanded(!isExpanded)}
            title="Hours of Operation"
            content={content}
          />
        ) : (
          <Dropdown
            isOpen={isExpanded}
            onClose={() => setIsExpanded(!isExpanded)}
            content={content}
            watchEdges={true}
          />
        )}
      </div>
    </div>
  );
}
