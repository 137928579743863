import React from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import { kebabCase, get } from 'lodash';
import { Link } from 'react-router-dom';
import { Divider } from 'common/lazy';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import { ContentLink, ExternalLink } from '../Link';
import { Content } from '../Content';
import { ListItem } from '../ListItem';
import { ResponsiveImage } from '../ResponsiveImage';
import { Video } from '../Video';
import getContentfulField from './getContentfulField';

const LINK_REG = /^https?:/;
const IMAGE_TYPE_REG = /^image/;
const VIDEO_TYPE_REG = /^video/;

function getPathForEntryType(entryType) {
  // Note can't link to venues currently as the slug
  // isn't in contentful.
  switch (entryType) {
    case 'article':
      return '/article/';
    case 'event':
      return '/event/';
    case 'page':
      return '/';
  }
}

export default class ContentfulRichText extends React.Component {
  constructor(props) {
    super(props);
    this.blocks = {
      [BLOCKS.HR]: this.renderDivider,
      [BLOCKS.PARAGRAPH]: this.renderParagraph,
      [BLOCKS.LIST_ITEM]: this.renderUnordenedListItem,
      [BLOCKS.HEADING_1]: this.renderHeading,
      [BLOCKS.HEADING_2]: this.renderHeading,
      [BLOCKS.HEADING_3]: this.renderHeading,
      [BLOCKS.HEADING_4]: this.renderHeading,
      [BLOCKS.HEADING_5]: this.renderHeading,
      [BLOCKS.HEADING_6]: this.renderHeading,
      [BLOCKS.EMBEDDED_ASSET]: this.renderEmbeddedAsset,
    };
    this.inlines = {
      [INLINES.HYPERLINK]: this.renderHyperlink,
      [INLINES.ASSET_HYPERLINK]: this.renderAssetHyperlink,
      [INLINES.ENTRY_HYPERLINK]: this.renderEntryHyperlink,
    };
  }

  render() {
    const { field, small, lang, value, ...rest } = this.props;
    const content = value || getContentfulField(field, lang);
    if (!content) {
      return null;
    } else if (typeof content === 'string') {
      return content;
    }
    return (
      <Content small={small} {...rest}>
        {this.renderBody(content)}
      </Content>
    );
  }

  renderBody(doc) {
    // Contentful libs are doing out of bounds array checking which
    // throws warnings with mobx, so make sure that it is converted
    // to a real JS object here.
    return documentToReactComponents(toJS(doc), {
      renderNode: {
        ...this.blocks,
        ...this.inlines,
      },
    });
  }

  renderDivider = () => {
    return <Divider />;
  };

  renderParagraph = (node) => {
    const text = documentToPlainTextString(node);
    if (text) {
      // This is actually slightly tricky. If we
      // re-render with the same options it will
      // cause infinite recursion on the same node,
      // so only include inline options here.
      if (this.props.args) {
        return documentToReactComponents(
          {
            ...node,
            content: node.content
              .reduce((acc, cur) => {
                const last = acc[acc.length - 1];
                if (
                  last &&
                  last.nodeType === cur.nodeType &&
                  last.marks.length === cur.marks.length &&
                  last.marks
                    .map((mark) => mark.type)
                    .every((mark) =>
                      cur.marks.map((a) => a.type).includes(mark)
                    )
                ) {
                  return acc.map((content, index) => {
                    if (index !== acc.length - 1) return content;

                    return {
                      ...content,
                      value: `${content.value}${cur.value}`,
                    };
                  });
                }
                return [...acc, cur];
              }, [])
              .map((content) => {
                if (content.nodeType !== 'text') return content;

                return {
                  ...content,
                  value: this.props.args
                    ? Object.entries(this.props.args).reduce(
                        (acc, [propName, value]) => {
                          return acc.replaceAll('${' + propName + '}', value);
                        },
                        content.value
                      )
                    : content.value,
                };
              }),
          },
          {
            renderNode: {
              ...this.inlines,
            },
          }
        );
      }

      return documentToReactComponents(node, {
        renderNode: {
          ...this.inlines,
        },
      });
    } else {
      return <br />;
    }
  };

  renderUnordenedListItem = (node) => {
    const text = node;
    if (text) {
      return (
        <ListItem>
          {documentToReactComponents(node, { renderNode: { ...this.inlines } })}
        </ListItem>
      );
    } else {
      return <br />;
    }
  };

  renderHeading = (node, children) => {
    const id = kebabCase(
      documentToPlainTextString(node).replace(/^\d\.\s*/g, '')
    );
    const Tag = node.nodeType.replace(/(h)eading-(\d)/, '$1$2');
    return <Tag id={id}>{children}</Tag>;
  };

  renderHyperlink = (node, children) => {
    return <ContentLink href={node.data.uri}>{children}</ContentLink>;
  };

  renderEntryHyperlink = (node, children) => {
    const { sys, fields } = node.data.target;
    const entryType = sys.contentType.sys.id;
    const path = getPathForEntryType(entryType) + this.getField(fields, 'slug');
    return <Link to={path}>{children}</Link>;
  };

  renderAssetHyperlink = (node, children) => {
    const file = this.getField(node, 'data', 'target', 'fields', 'file');
    if (file.contentType === 'application/pdf') {
      //TODO: This is a hot fix, we need to be able to manage any kind of content type
      return <ExternalLink href={file.url}>{children}</ExternalLink>;
    }
    return <ResponsiveImage src={file.url} fluid />;
  };

  renderEmbeddedAsset = (node) => {
    const { fields } = node.data.target;
    const file = this.getField(fields, 'file');
    const title = this.getField(fields, 'title');
    const description = this.getField(fields, 'description');
    let content = this.renderMedia(file, title);
    if (LINK_REG.test(description)) {
      content = <ExternalLink href={description}>{content}</ExternalLink>;
    }
    return content;
  };

  renderMedia = (file, title) => {
    const url = get(file, ['url']);
    const contentType = get(file, ['contentType']);
    const imageDetails = get(file, ['details', 'image']);
    if (IMAGE_TYPE_REG.test(contentType)) {
      let ratio = null;
      if (imageDetails) {
        ratio = imageDetails.width / imageDetails.height;
      }
      return <ResponsiveImage src={url} alt={title} ratio={ratio} fluid />;
    } else if (VIDEO_TYPE_REG.test(contentType)) {
      const { autoplayVideo } = this.props;
      return (
        <Video src={url} type={contentType} controls autoPlay={autoplayVideo} />
      );
    } else {
      throw new TypeError(`Unknown content type ${contentType}`);
    }
  };

  // Object may be returned from the Contentful Preview API which
  // maps fields to current locale, so object may not be nested.
  getField(obj, ...path) {
    const field = get(obj, path);
    return (field && field['en-US']) || field;
  }
}

ContentfulRichText.propTypes = {
  field: PropTypes.object,
  small: PropTypes.bool,
  autoplayVideo: PropTypes.bool,
};

ContentfulRichText.defaultProps = {
  small: false,
};
