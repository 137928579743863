import { useState, useEffect } from 'react';

export default function useBannerVisibility() {
  const [isBannerVisible, setIsBannerVisible] = useState(false);

  useEffect(() => {
    const bannerSelector = '.navigation-header__banner-container';
    const closeButtonSelector =
      '.navigation-header__banner-container .banner__close';

    const checkBanner = () => {
      const banner = document.querySelector(bannerSelector);
      setIsBannerVisible(!!banner);
    };

    checkBanner();

    const handleClose = () => {
      setIsBannerVisible(false);
    };

    const attachListener = () => {
      const closeButton = document.querySelector(closeButtonSelector);
      if (closeButton) {
        closeButton.addEventListener('click', handleClose, { once: true });
      }
    };

    attachListener();

    const observer = new MutationObserver(() => {
      checkBanner();
      attachListener();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
      const closeButton = document.querySelector(closeButtonSelector);
      if (closeButton) {
        closeButton.removeEventListener('click', handleClose);
      }
    };
  }, []);

  return isBannerVisible;
}
