import React from 'react';

import { useClassName } from 'common/hooks';
import useCustomStyle from '../../hooks';

import './carousel-controls.less';

export default function CarouselControls({ children, settings }) {
  const classNames = useClassName('CLPCarouselControls');
  const [, customStyle] = useCustomStyle(settings);

  const CarouselControlsStyles = Object.fromEntries(
    Object.entries(customStyle)
      .filter(([key]) => key.startsWith('--carousel-controls-'))
      .map(([key, value]) => [key.replace('--carousel-controls-', ''), value])
  );

  return (
    <div className={classNames('container')} style={CarouselControlsStyles}>
      {children}
    </div>
  );
}
