import React, { useRef, useState } from 'react';
import { inject } from 'mobx-react';

import { SVGIcon } from 'common/components';
import { useClassName } from 'common/hooks';
import { identify, track } from 'utils/analytics';
import { isValidEmail } from 'utils/helpers';
import HomepageAsset from 'public/screens/Home/Components/Asset';
import Center from '../../Center';
import useCustomStyle from '../../../hooks';

import './avenue-subscribe.less';

export default inject(
  'me',
  'subscriptions'
)(function Subscribe({ component, me, subscriptions }) {
  const classNames = useClassName('CLPAvenueSubscribe');
  const [, styles] = useCustomStyle(component.fields.settings);
  const inputRef = useRef();
  const [error, setError] = useState(null);
  const [signedUp, setSignedUp] = useState(false);

  const title = styles['--title'] || '';
  const subtitle = styles['--subtitle'] || '';
  const asset = component.fields.settings.find(
    (item) => item.sys.contentType.sys.id === 'lpAsset'
  );

  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (signedUp) return;
    const email = inputRef.current.value;
    if (!isValidEmail(email)) {
      setError('Please enter a valid email address');
      return;
    }
    try {
      identify(me?.user || { email });

      await subscriptions.create({
        group: 'luxury_retail_interest',
        email,
      });
      track('Subscription', {
        group: 'luxury_retail_interest',
        url: location.pathname,
      });
      setSignedUp(true);
    } catch (e) {
      setError(e.message);
      console.error(`Error subscribing customer, error: ${e.message}`);
    }
  };

  return (
    <Center className={classNames('container')} style={styles}>
      {Boolean(asset) && (
        <HomepageAsset asset={asset} className={classNames('image')} />
      )}
      <div className={classNames('content')}>
        <div className={classNames('title')}>{title}</div>
        <div className={classNames('subtitle')}>{subtitle}</div>

        <div className={classNames('form')}>
          <input
            type="text"
            placeholder="email@example.com"
            ref={inputRef}
            className={classNames('input')}></input>
          {signedUp ? (
            <div className={classNames('success')}>
              <SVGIcon name="check" size="tiny" />
            </div>
          ) : (
            <button onClick={handleSubscribe} className={classNames('button')}>
              <span>Become a VIP</span>
              <SVGIcon name="arrow-right" size="tiny" />
            </button>
          )}
        </div>
        <div className={classNames('error')}>
          <span>{error}</span>
        </div>
      </div>
    </Center>
  );
});
