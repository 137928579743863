import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';

import { useClassName, useWindowSize } from 'common/hooks';
import { ContentfulRichText, ExternalLink } from 'common/components';
import HomepageAsset from 'public/screens/Home/Components/Asset';
import HomepageImage from 'public/screens/Home/Components/Image';
import googleMaps from 'common/assets/google-maps.svg';
import appleMaps from 'common/assets/apple-maps.svg';

import useCustomStyle from '../../hooks';
import useSlider from '../../../Home/Components/useSlider';

import './header.less';
import 'swiper/swiper.less';
import 'swiper/modules/effect-fade/effect-fade.less';

function TypeAHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-a', component.fields.theme || 'light'])}
      ref={setRef}>
      <div>
        <span>{component.fields.subtitle}</span>
        <h3>{component.fields.title}</h3>
      </div>
      <ContentfulRichText field={{ 'en-US': component.fields.description }} />
    </div>
  );
}

function TypeBHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-b', component.fields.theme || 'light'])}
      ref={setRef}>
      <h3>{component.fields.title}</h3>
    </div>
  );
}

function TypeCHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-c', component.fields.theme || 'light'])}
      ref={setRef}>
      <h3>{component.fields.title}</h3>
    </div>
  );
}

function TypeDHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-d', component.fields.theme || 'light'])}
      ref={setRef}>
      <h3>{component.fields.title}</h3>
    </div>
  );
}

function TypeEHeader({ component }) {
  const classNames = useClassName('CLPHeaderTypeE');
  const [setRef] = useCustomStyle(component.fields.settings);
  const [props] = useSlider();

  return (
    <div
      className={classNames(['container', component.fields.theme || 'light'])}
      ref={setRef}>
      <div>
        {Boolean(component?.fields?.tags?.length) && (
          <div className={classNames('tags')}>
            {component.fields.tags.map((tag) => (
              <span key={tag}>{tag}</span>
            ))}
          </div>
        )}
        {Boolean(component.fields.subtitle) && (
          <h4>{component.fields.subtitle}</h4>
        )}
        {Boolean(component.fields.title) && <h3>{component.fields.title}</h3>}

        {Boolean(component.fields.description) && (
          <p>
            <ContentfulRichText
              field={{ 'en-US': component.fields.description }}
            />
          </p>
        )}
        {Boolean(component.fields.icon && component.fields.iconText) && (
          <span>
            <HomepageImage field={component.fields.icon} />
            <span>{component.fields.iconText}</span>
          </span>
        )}
      </div>
      <div className={classNames('slider')}>
        <Swiper
          {...(component.fields.assets.length > 1 && {
            modules: [EffectFade, Autoplay],
            effect: 'fade',
            fadeEffect: {
              crossFade: true,
            },
            autoplay: {
              delay: 5000,
            },
            loopAdditionalSlides: component.fields.assets.length,
            loop: true,
          })}
          centeredSlides
          slidesPerView="auto"
          {...props}>
          {component.fields.assets.map((asset, index) => (
            <SwiperSlide key={index}>
              <HomepageAsset
                key={asset.sys.id}
                className={classNames('item')}
                asset={asset}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

function TypeFHeader({ component }) {
  const classNames = useClassName('CLPHeader');
  const [setRef, styles] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-f', component.fields.theme || 'light'])}
      ref={setRef}>
      {Boolean(component.fields.subtitle) && (
        <h4
          {...(Boolean(styles['--secondary-color']) && {
            style: {
              color: styles['--secondary-color'],
            },
          })}>
          {component.fields.subtitle}
        </h4>
      )}
      <h3>{component.fields.title}</h3>
      {Boolean(component.fields.description) && (
        <ContentfulRichText field={{ 'en-US': component.fields.description }} />
      )}
    </div>
  );
}

function TypeGHeader({ component }) {
  const classNames = useClassName('CLPHeaderTypeG');
  const [setRef] = useCustomStyle(component.fields.settings);

  return (
    <div
      className={classNames(['type-g', component.fields.theme || 'light'])}
      ref={setRef}>
      <div className={classNames('content')}>
        <div className={classNames('title')}>{component.fields.title}</div>
        <div className={classNames('subtitle')}>
          {component.fields.subtitle}
        </div>
      </div>
    </div>
  );
}

function TypeHHeader({ component }) {
  const classNames = useClassName('CLPHeaderTypeH');
  const [setRef, styles] = useCustomStyle(component.fields.settings);
  const { isMobile } = useWindowSize();
  const googleUrl = styles['--google-url'];
  const appleUrl = styles['--apple-url'];

  const renderLink = (url, icon, text) =>
    url && (
      <ExternalLink href={url} className={classNames('button')}>
        <img src={icon} alt={text} />
        {text}
      </ExternalLink>
    );

  return (
    <div className={classNames('container')} ref={setRef}>
      <div className={classNames('content')}>
        <div className={classNames('title')}>{component.fields.title}</div>
        <div className={classNames('subtitle')}>
          {component.fields.subtitle}
        </div>
        <div className={classNames('description')}>
          <ContentfulRichText
            field={{ 'en-US': component.fields.description }}
          />
        </div>
      </div>
      <div className={classNames('img-container')}>
        <HomepageAsset
          key={component.fields.assets[0].sys.id}
          className={classNames('image')}
          asset={component.fields.assets[0]}
        />
        <div className={classNames('button-overlay')}>
          {renderLink(googleUrl, googleMaps, 'View on Google Maps')}
          {renderLink(appleUrl, appleMaps, 'View on Apple Maps')}
        </div>
      </div>
      {isMobile && (
        <>
          <div className={classNames('description-mobile')}>
            <ContentfulRichText
              field={{ 'en-US': component.fields.description }}
            />
          </div>
          <div className={classNames('mobile-buttons')}>
            {renderLink(googleUrl, googleMaps, 'View on Google Maps')}
            {renderLink(appleUrl, appleMaps, 'View on Apple Maps')}
          </div>
        </>
      )}
    </div>
  );
}

export default function Header({ component }) {
  const Component = {
    'Type A': TypeAHeader,
    'Type B': TypeBHeader,
    'Type C': TypeCHeader,
    'Type D': TypeDHeader,
    'Type E': TypeEHeader,
    'Type F': TypeFHeader,
    'Type G': TypeGHeader,
    'Type H': TypeHHeader,
  }[component.fields.type];

  return <Component component={component} />;
}
