import React from 'react';

import { useClassName } from 'common/hooks';

import './section-list.less';

export function SectionTitle({ children }) {
  const classNames = useClassName('SectionList');

  return <h4 className={classNames('title')}>{children}</h4>;
}

export function SectionItem({ title, children, style }) {
  const classNames = useClassName('SectionList');

  return (
    <div className={classNames('item')}>
      <SectionTitle>{title}</SectionTitle>
      <div style={style}>{children}</div>
    </div>
  );
}

export function SectionList({ children }) {
  const classNames = useClassName('SectionList');
  return <div className={classNames('list')}>{children}</div>;
}
