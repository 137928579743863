import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import FastMarquee from 'react-fast-marquee';
import { createPortal } from 'react-dom';

import { useClassName } from 'common/hooks';
import { ControlledTabs } from 'public/components/Tabs';
import { SVGIcon } from 'common/components';
import { Modal } from 'common/lazy';

import useSlider from '../../../Home/Components/useSlider';
import HomepageAsset from '../../../Home/Components/Asset';
import CarouselArrows from '../CarouselArrows';
import CarouselControls from '../CarouselControls';
import CarouselCounter from '../CarouselCounter';
import useCustomStyle from '../../hooks';
import LPCard from '../Card';
import useLoop from '../../../Home/Components/useLoop';

import './carousel.less';
import 'swiper/swiper.less';

function Tabs({ setTagIndex, tagIndex, tags }) {
  const classNames = useClassName('CLPCarousel');

  if (!tags?.length) return null;

  return (
    <ControlledTabs
      names={tags}
      selectedIndex={tagIndex}
      styles={{ container: classNames('tabs') }}
      onClick={(tagName) => {
        setTagIndex(tags.findIndex((tag) => tag === tagName));
      }}
    />
  );
}

function Asset({ setPaused, slide }) {
  const classNames = useClassName('CLPCarousel');
  return (
    <div
      className={classNames('slide')}
      {...(!!setPaused && {
        onMouseOver: () => setPaused(true),
        onMouseOut: () => setPaused(false),
      })}>
      <HomepageAsset asset={slide} />
    </div>
  );
}

function Card({ setPaused, slide }) {
  const classNames = useClassName('CLPCarousel');
  return (
    <div
      className={classNames('slide')}
      onMouseOver={() => setPaused(true)}
      onMouseOut={() => setPaused(false)}>
      <LPCard item={slide} />
    </div>
  );
}

function getBreakpoints(component) {
  const slidesPerView = component.fields.settings.find(
    (setting) => setting.fields.key === '--slides-per-view'
  );
  if (!slidesPerView)
    return {
      0: {
        slidesPerView: 'auto',
      },
    };

  return {
    0: {
      slidesPerView: slidesPerView.fields.mobileValue,
    },
    768: {
      slidesPerView: slidesPerView.fields.tabletValue,
    },
    1128: {
      slidesPerView: slidesPerView.fields.desktopValue,
    },
  };
}

function Slider({ component, tagIndex, paused, setPaused }) {
  const classNames = useClassName('CLPCarousel');
  const [, styles] = useCustomStyle(component.fields.settings);
  const [props, methods, state] = useSlider();
  const breakpoints = getBreakpoints(component);
  const tags = [
    ...new Set(component.fields.slides.map((a) => a.fields.tags).flat()),
  ].filter(Boolean);
  const slides = component.fields.groupTags
    ? component.fields.slides.filter((slide) =>
        slide.fields?.tags?.includes(tags[tagIndex])
      )
    : component.fields.slides;
  const loop = useLoop({ breakpoints, slidesLength: slides.length });
  const transitionSpeed = parseInt(styles['--transition-speed']) || 300;

  return (
    <>
      <Swiper
        height={500}
        centeredSlides={styles['--centered-slides'] !== 'false'}
        slidesPerView="auto"
        spaceBetween={parseInt(styles['--space-between']) || 0}
        speed={transitionSpeed}
        {...props}
        {...loop}
        className={classNames(['wrapper', !loop.loop && 'no-loop'])}>
        {slides.map((slide, index) => (
          <SwiperSlide
            key={index}
            className={classNames('slide-container')}
            style={Object.fromEntries(
              Object.entries(styles)
                .filter(([key]) => key.startsWith('--slide'))
                .map(([key, value]) => [key.replace('--slide-', ''), value])
            )}>
            {({ isActive }) => {
              return slide.sys.contentType.sys.id === 'lpAsset' ? (
                <Asset
                  slide={slide}
                  setPaused={setPaused}
                  isActive={isActive}
                />
              ) : (
                <Card slide={slide} setPaused={setPaused} isActive={isActive} />
              );
            }}
          </SwiperSlide>
        ))}
      </Swiper>
      {slides.length > 1 && loop.loop && (
        <CarouselControls settings={component.fields.settings}>
          <CarouselCounter
            current={state.activeIndex + 1}
            total={slides.length}
            next={methods.slideNext}
            goTo={methods.slideTo}
            paused={paused}
            autoplayDuration={component.fields.autoplayDuration}
            type={component.fields.type}
            theme={component.fields.theme}
          />
          <CarouselArrows
            next={methods.slideNext}
            prev={methods.slidePrev}
            type={component.fields.type}
            theme={component.fields.theme}
            settings={component.fields.settings}
          />
        </CarouselControls>
      )}
    </>
  );
}

function Marquee({ component, onClick, ...props }) {
  const [, styles] = useCustomStyle(component.fields.settings);

  return (
    <FastMarquee pauseOnHover {...props} autoFill>
      {component.fields.slides.map((slide) => {
        return (
          <div
            key={slide.sys.id}
            style={{
              margin: '8px',
              borderRadius: '32px',
              overflow: 'hidden',
              cursor: 'pointer',
              ...Object.fromEntries(
                Object.entries(styles)
                  .filter(([key]) => key.startsWith('--slide'))
                  .map(([key, value]) => [key.replace('--slide-', ''), value])
              ),
            }}
            onClick={() => onClick(slide.sys.id)}>
            {slide.sys.contentType.sys.id === 'lpAsset' ? (
              <Asset slide={slide} />
            ) : (
              <Card slide={slide} />
            )}
          </div>
        );
      })}
    </FastMarquee>
  );
}

function CarouselTypeC({ component, paused, setPaused }) {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const classNames = useClassName('CLPCarousel');

  const next = () => {
    if (selectedIndex === component.fields.slides.length - 1) {
      setSelectedIndex(0);
    } else {
      setSelectedIndex((selectedIndex) => selectedIndex + 1);
    }
  };

  const prev = () => {
    if (selectedIndex === 0) {
      setSelectedIndex(component.fields.slides.length - 1);
    } else {
      setSelectedIndex((selectedIndex) => selectedIndex - 1);
    }
  };

  const onClick = (id) => {
    setSelectedIndex(
      component.fields.slides.findIndex((slide) => slide.sys.id === id)
    );
  };

  const selected = component.fields.slides[selectedIndex];
  return (
    <>
      <Marquee
        onClick={onClick}
        component={{
          ...component,
          fields: {
            ...component.fields,
            slides: component.fields.slides.slice(
              0,
              Math.ceil(component.fields.slides.length / 2)
            ),
          },
        }}
      />
      <Marquee
        direction="right"
        onClick={onClick}
        component={{
          ...component,
          fields: {
            ...component.fields,
            slides: component.fields.slides.slice(
              Math.ceil(component.fields.slides.length / 2)
            ),
          },
        }}
      />
      {selected && (
        <Modal
          onClose={() => setSelectedIndex(null)}
          className={classNames('marquee-modal-wrapper')}
          centered
          open={true}>
          {createPortal(
            <div
              className={classNames('close-button')}
              onClick={() => setSelectedIndex(null)}>
              <SVGIcon name="close" size="tiny" />
            </div>,
            document.getElementsByTagName('body')[0]
          )}
          <div className={classNames('modal-container')}>
            <div className={classNames('marquee-modal')}>
              {selected.sys.contentType.sys.id === 'lpAsset' ? (
                <Asset slide={selected} paused={paused} setPaused={setPaused} />
              ) : (
                <Card slide={selected} paused={paused} setPaused={setPaused} />
              )}
            </div>
            <CarouselControls>
              <CarouselCounter
                current={selectedIndex + 1}
                total={component.fields.slides.length}
                next={next}
                paused={paused}
                autoplayDuration={component.fields.autoplayDuration}
                type={'Type B'}
              />
              {createPortal(
                <CarouselArrows
                  next={next}
                  prev={prev}
                  type={'Type B'}
                  style={{ position: 'fixed', zIndex: 99999, display: 'flex' }}
                />,
                document.getElementsByTagName('body')[0]
              )}
            </CarouselControls>
          </div>
        </Modal>
      )}
    </>
  );
}

export default function Carousel({ component }) {
  const [paused, setPaused] = useState(false);
  const [tagIndex, setTagIndex] = useState(0);

  if (component.fields.type === 'Type C') {
    return (
      <CarouselTypeC
        component={component}
        paused={paused}
        setPaused={setPaused}
      />
    );
  }

  const tags = [
    ...new Set(component.fields.slides.map((a) => a.fields.tags).flat()),
  ].filter(Boolean);

  const groupTags = Boolean(component.fields.groupTags && tags.length > 0);
  return (
    <div style={{ position: 'relative' }}>
      {groupTags && (
        <Tabs
          tagIndex={tagIndex}
          setTagIndex={(i) => {
            setTagIndex(i);
          }}
          tags={tags}
        />
      )}

      {groupTags ? (
        tags.map((tag, index) => {
          return (
            <div
              key={index}
              {...(tagIndex !== index && { style: { display: 'none' } })}>
              <Slider
                component={component}
                tagIndex={index}
                paused={paused}
                setPaused={setPaused}
              />
            </div>
          );
        })
      ) : (
        <Slider component={component} paused={paused} setPaused={setPaused} />
      )}
    </div>
  );
}
