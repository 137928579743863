import React from 'react';
import { useClassName } from 'common/hooks';
import Center from '../Center';
import useCustomStyle from '../../hooks';
import HomepageAsset from '../../../Home/Components/Asset';

import './gallery.less';

export default function Gallery({ component }) {
  const classNames = useClassName('CLPGallery');
  const [setRef, style] = useCustomStyle(component.fields.settings);
  const items = component.fields.items;

  return (
    <div ref={setRef}>
      <Center className={classNames('container')} style={style}>
        {items.slice(0, 6).map((item, i) => (
          <div
            key={item.sys.id}
            className={classNames(['imageWrapper', `item-${i}`])}>
            <HomepageAsset asset={item} className={classNames('image')} />
          </div>
        ))}
      </Center>
    </div>
  );
}
