import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { useClassName } from 'common/hooks';
import { LazyDayPicker } from 'common/lazy';

import './simple-calendar.less';

const SimpleCalendar = ({
  initialDate,
  updateSelectedDate,
  isDateUnavailable,
}) => {
  const className = useClassName('SimpleCalendar');
  const [calendarSelectedDate, setCalendarSelectedDate] = useState(
    initialDate ? DateTime.fromISO(initialDate).toJSDate() : null
  );
  const today = new Date();

  useEffect(() => {
    if (!initialDate) return;

    setCalendarSelectedDate(DateTime.fromISO(initialDate).toJSDate());
  }, [initialDate]);

  const isDateBeforeToday = (date) => {
    return new Date(date.toDateString()) < new Date(new Date().toDateString());
  };

  const toISO = (jsDate) => DateTime.fromJSDate(jsDate).toISODate();

  return (
    <div className={className('calendar')}>
      <LazyDayPicker
        className="day-picker"
        disabledDays={[
          { before: today },
          (jsDate) => isDateUnavailable(toISO(jsDate)),
        ]}
        enableOutsideDaysClick={false}
        selectedDays={calendarSelectedDate}
        month={calendarSelectedDate}
        fixedWeeks
        onDayClick={(date) => {
          if (isDateBeforeToday(date) || isDateUnavailable(toISO(date))) return;

          updateSelectedDate(date);
        }}
      />
    </div>
  );
};

SimpleCalendar.propTypes = {
  initialDate: PropTypes.string,
  updateSelectedDate: PropTypes.func.isRequired,
  isDateUnavailable: PropTypes.func.isRequired,
  trigger: PropTypes.any,
};

SimpleCalendar.defaultProps = {
  isDateUnavailable: () => false,
};

export default SimpleCalendar;
