import React from 'react';

export function CircleParking() {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 20 20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <clipPath id="a">
        <path d="m0 0h20v20h-20z" />
      </clipPath>
      <g clipPath="url(#a)">
        <path
          d="m7.49996 14.1666v-8.33331h3.33334c.663 0 1.2989.26339 1.7678.73224.4688.46884.7322 1.10472.7322 1.76776s-.2634 1.29893-.7322 1.76781c-.4689.4688-1.1048.7322-1.7678.7322h-3.33334m10.83334-.83334c0 4.60234-3.731 8.33334-8.33334 8.33334-4.60237 0-8.33333-3.731-8.33333-8.33334 0-4.60237 3.73096-8.33333 8.33333-8.33333 4.60234 0 8.33334 3.73096 8.33334 8.33333z"
          stroke="#8C98A8"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  );
}
