import React from 'react';

import { useClassName } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import HomepageAsset from 'public/screens/Home/Components/Asset';

import Actions from '../Actions';
import Link from '../Link';

import './card.less';
import HomepageImage from '../../../Home/Components/Image';

function CardTypeA({ item }) {
  const classNames = useClassName('CLPCardTypeA');

  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      {Boolean(item?.fields?.title || item?.fields?.links?.length) && (
        <div>
          <h4>{item.fields.title}</h4>
          {Boolean(item?.fields?.links?.length) && (
            <Actions>
              {item.fields.links.map((link) => (
                <Link key={link.sys.id} component={link} />
              ))}
            </Actions>
          )}
        </div>
      )}
    </div>
  );
}

function CardTypeB({ item }) {
  const classNames = useClassName('CLPCardTypeB');

  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      <div>
        <h4>{item.fields.title}</h4>
        <p>
          <ContentfulRichText field={{ 'en-US': item.fields.description }} />
        </p>
        {Boolean(item?.fields?.links?.length) && (
          <Actions>
            {item.fields.links.map((link) => (
              <Link key={link.sys.id} component={link} />
            ))}
          </Actions>
        )}
      </div>
    </div>
  );
}

function CardTypeC({ item }) {
  const classNames = useClassName('CLPCardTypeC');
  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      <div>
        <span>
          <HomepageImage field={item.fields.icon} /> {item.fields.subtitle}
        </span>
        <h4>{item.fields.title}</h4>
        <p>
          <ContentfulRichText field={{ 'en-US': item.fields.description }} />
        </p>
        {Boolean(item?.fields?.links?.length) && (
          <Actions className={classNames('actions')}>
            {item.fields.links.map((link) => (
              <Link key={link.sys.id} component={link} />
            ))}
          </Actions>
        )}
      </div>
    </div>
  );
}

function CardTypeD({ item }) {
  const classNames = useClassName('CLPCardTypeD');
  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      <div>
        <span>
          <HomepageImage field={item.fields.icon} /> {item.fields.subtitle}
        </span>
        <h4>{item.fields.title}</h4>
        <p>
          <ContentfulRichText field={{ 'en-US': item.fields.description }} />
        </p>
        {Boolean(item?.fields?.links?.length) && (
          <Actions className={classNames('actions')}>
            {item.fields.links.map((link) => (
              <Link key={link.sys.id} component={link} />
            ))}
          </Actions>
        )}
      </div>
    </div>
  );
}

function CardTypeE({ item }) {
  const classNames = useClassName('CLPCardTypeE');

  const hasLink = Boolean(item?.fields?.links?.length);
  if (!hasLink) {
    return (
      <div className={classNames('container')}>
        <HomepageAsset asset={item.fields.asset} />
      </div>
    );
  }

  return (
    <div className={classNames('container')}>
      <Link component={item.fields.links[0]}>
        <HomepageAsset asset={item.fields.asset} />
      </Link>
    </div>
  );
}

function CardTypeF({ item }) {
  const classNames = useClassName('CLPCardTypeF');

  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      <ContentfulRichText field={{ 'en-US': item.fields.description }} />
      <span>
        {item.fields.subtitle}
        <HomepageImage field={item.fields.icon} />
      </span>
    </div>
  );
}

function CardTypeG({ item }) {
  const classNames = useClassName('CLPCardTypeG');

  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      {Boolean(item?.fields?.links?.length) && (
        <Link component={item.fields.links[0]} />
      )}
    </div>
  );
}

function CardTypeH({ item }) {
  // Used for Avenue Store Cards
  const classNames = useClassName('CLPCardTypeH');

  const handleClick = () => {
    if (item?.fields?.links?.length) {
      const link = item.fields.links[0];
      window.location.href = link.fields.url;
    }
  };

  return (
    <div
      className={classNames('container')}
      onClick={item?.fields?.links?.length ? handleClick : undefined}>
      <HomepageAsset asset={item.fields.asset} />
      {item.fields.logoOverlay && (
        <div className={classNames('logo-overlay')}>
          <HomepageImage field={item.fields.logoOverlay} />
        </div>
      )}
    </div>
  );
}

function CardTypeI({ item }) {
  // Used for Avenue Life in Luxury Carousel Cards (Ferrari, Art, etc..)
  const classNames = useClassName('CLPCardTypeI');

  return (
    <div className={classNames('container')}>
      <HomepageAsset
        asset={item.fields.asset}
        className={classNames('image')}
      />
      <div className={classNames('gradient')} />
      <div className={classNames('content')}>
        <div className={classNames('subtitle')}>{item.fields.subtitle}</div>
        <div className={classNames('title')}>{item.fields.title}</div>
        <div className={classNames('description')}>
          <ContentfulRichText field={{ 'en-US': item.fields.description }} />
        </div>

        {Boolean(item?.fields?.links?.length) && (
          <Actions className={classNames('actions')}>
            {item.fields.links.map((link) => (
              <Link key={link.sys.id} component={link} />
            ))}
          </Actions>
        )}
      </div>
    </div>
  );
}

function CardTypeJ({ item }) {
  // Used in Avenue Dining Cards (Carpaccio, Isola, etc..)
  const classNames = useClassName('CLPCardTypeJ');

  return (
    <div className={classNames('container')}>
      <HomepageAsset asset={item.fields.asset} />
      {Boolean(item?.fields?.title || item?.fields?.links?.length) && (
        <div>
          <h4>{item.fields.title}</h4>
          {Boolean(item?.fields?.links?.length) && (
            <Actions>
              {item.fields.links.map((link) => (
                <Link key={link.sys.id} component={link} />
              ))}
            </Actions>
          )}
        </div>
      )}
    </div>
  );
}

export default function Card({ item }) {
  const type = item.fields.type || 'Type A';

  const Component = {
    'Type A': CardTypeA,
    'Type B': CardTypeB,
    'Type C': CardTypeC,
    'Type D': CardTypeD,
    'Type E': CardTypeE,
    'Type F': CardTypeF,
    'Type G': CardTypeG,
    'Type H': CardTypeH,
    'Type I': CardTypeI,
    'Type J': CardTypeJ,
  }[type];

  return <Component item={item} />;
}
