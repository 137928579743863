import React from 'react';
import PropTypes from 'prop-types';
import { Form, Loader } from 'common/lazy';
import { DateTime } from 'luxon';
import { inject, observer } from 'mobx-react';

import { formatApiDate, parseApiDate } from 'utils/api';
import { Component } from 'common/helpers';
import {
  Layout,
  FormActions,
  DatePicker,
  SVGIcon as Icon,
  ContentfulRichText,
  ResponsiveImageLight,
  DesktopOnlyFix as DesktopOnly,
  TabletOnly,
  MobileOnly,
  NumericUpDown,
} from 'common/components';
import { Spacer } from '../Spacer';
import { Totals } from '../Totals';
import SessionSelector from './components/SessionSelector';
import { SectionItem, SectionList } from '../SectionList';

import './bundle-selector.less';

const CAPACITY_ERROR_MESSAGE =
  'There is no capacity. Please, decrement the amount selected or select another date.';

@inject('ticketInventory', 'cart', 'bundles')
@observer
export default class BundleSelector extends Component {
  ticketTypeRef = null;

  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      quantities: this.props.quantity || 0,
      loading: true,
      tickets: [],
      noAvailability: {},
      bundleCartId: 'BUND-' + Date.now(),
      availabilityPerBundle: [],
      reservationDates: {},
    };
  }

  componentDidMount() {
    const { bundle, date, venueSlug, quantity } = this.props;

    bundle.rollerProducts.forEach(async (product) => {
      await this.props.bundles.fetchUnavailabilityDates(product.productId);

      if (product.hiddenDatePicker) {
        const date = DateTime.max(
          DateTime.fromISO(bundle.startDate),
          DateTime.local()
        ).toJSDate();
        await this.onDayChange(date, product);
      }

      if (date && quantity && venueSlug && venueSlug === product.venueSlug) {
        await this.onDayChange(
          DateTime.fromISO(this.props.date).plus({ hours: 12 }).toJSDate(),
          product
        );
      }
    });
  }

  setQuantity(quantity) {
    this.setState({
      quantities: quantity,
    });
  }

  updateProductAvailability(availability) {
    const { productId } = availability;
    let toUpdate = [];
    let wasUpdated = false;
    if (this.state.availabilityPerBundle.length === 0) {
      toUpdate.push(availability);
      this.setState({ availabilityPerBundle: toUpdate });
      return;
    }
    toUpdate = this.state.availabilityPerBundle.map((record) => {
      if (record.productId === productId) {
        wasUpdated = true;
        return availability;
      }
      return record;
    });
    if (!wasUpdated) {
      toUpdate.push(availability);
    }
    this.setState({ availabilityPerBundle: toUpdate });
  }

  existAvailability(quantities) {
    const { rollerProducts } = this.props.bundle;
    const { availabilityPerBundle } = this.state;
    const availability = { capacity: true };
    if (
      quantities === 0 ||
      rollerProducts.length > availabilityPerBundle.length
    )
      return availability;
    const unavailable = availabilityPerBundle.filter(
      (product) => product.capacityRemaining < quantities
    );
    if (unavailable.length > 0) {
      availability.capacity = false;
      unavailable.forEach((product) => {
        availability[product.productId] = false;
      });
    }
    return availability;
  }

  async getProductAvailability(reservationDate, rollerProduct) {
    const { productId, externalTicket, venueId } = rollerProduct;
    const date = reservationDate;
    const { quantities } = this.state;

    const productAvailability = await this.props.bundles.getProductAvailability(
      {
        productId,
        externalTicket,
        venueId,
        quantity: quantities,
        date,
      }
    );

    if (productAvailability?.available) {
      this.updateProductAvailability({
        productId,
        date,
        capacityRemaining: productAvailability.capacityRemaining,
      });
    }

    return productAvailability;
  }

  getUniqueSessionData(sessions) {
    const { quantities } = this.state;
    if (!sessions || sessions?.length === 0 || sessions?.length > 1)
      return { sessionProductId: null, startTime: null };
    const session = sessions[0];
    if (quantities >= session.capacityRemaining)
      return { sessionProductId: null, startTime: null };
    return {
      sessionProductId: session.productId,
      startTime: session.startTime,
    };
  }

  onDayChange = async (reservationDate, rollerProduct) => {
    const { productId } = rollerProduct;
    const { tickets, reservationDates, noAvailability, errors } = this.state;

    const productAvailability = await this.getProductAvailability(
      reservationDate,
      rollerProduct
    );
    const updatedTickets = tickets;
    const updatedReservationDates = reservationDates;
    const updatedNoAvailability = noAvailability;
    const updatedErrors = errors;
    const existingTicketIndex = tickets.findIndex(
      (ticket) => ticket.productId === productId
    );
    if (existingTicketIndex > -1) updatedTickets.splice(existingTicketIndex, 1);

    if (!productAvailability?.available) {
      updatedErrors[`${productId}`] = productAvailability?.message;
      updatedNoAvailability[`${productId}`] = true;
      await this.props.bundles.fetchUnavailabilityDates(productId);
    } else {
      const { sessionProductId, startTime } = this.getUniqueSessionData(
        productAvailability.sessions
      );
      updatedTickets.push({
        ...rollerProduct,
        sessionProductId,
        startTime,
      });
      updatedErrors[`${productId}`] = null;
      updatedNoAvailability[`${productId}`] = false;
    }

    updatedReservationDates[`${productId}`] = reservationDate;

    this.setState({
      tickets: updatedTickets,
      reservationDates: updatedReservationDates,
      noAvailability: updatedNoAvailability,
      errors: updatedErrors,
    });
  };

  onPassportDayChange = async (reservationDate, rollerProducts) => {
    const { tickets, reservationDates, noAvailability, errors } = this.state;

    let updatedTickets = tickets;
    let updatedReservationDates = reservationDates;
    let updatedNoAvailability = noAvailability;
    let updatedErrors = errors;

    for (const rollerProduct of rollerProducts) {
      const { productId } = rollerProduct;
      const productAvailability = await this.getProductAvailability(
        reservationDate,
        rollerProduct
      );

      if (!productAvailability?.available) {
        updatedErrors[`${productId}`] = productAvailability?.message;
        updatedNoAvailability[`${productId}`] = true;
        await this.props.bundles.fetchUnavailabilityDates(productId);
        break;
      } else {
        if (
          !updatedTickets.find(
            (ticket) => ticket.productId === rollerProduct.productId
          )
        ) {
          updatedTickets.push(rollerProduct);
        }
        updatedErrors[`${productId}`] = null;
        updatedNoAvailability[`${productId}`] = false;
        updatedReservationDates[`${productId}`] = reservationDate;
      }
    }

    this.setState({
      tickets: updatedTickets,
      reservationDates: updatedReservationDates,
      noAvailability: updatedNoAvailability,
      errors: updatedErrors,
    });
  };

  onSubmit = () => {
    const action = this.getAction();
    const { slug } = this.props.bundle;
    const { tickets, quantities, reservationDates, bundleCartId } = this.state;

    const formattedTickets = tickets.map((item) => {
      const {
        productId,
        ticketOptionId,
        name,
        venueId,
        externalTicket,
        cost,
        addons,
        startTime,
      } = item;

      return {
        name,
        price: cost,
        venueId,
        externalTicket,
        bookingItemId: productId,
        ticketOptionId,
        reservationDate: formatApiDate(reservationDates[`${productId}`]),
        quantity: quantities,
        bundleSlug: slug,
        bundleCartId,
        addons,
        startTime,
      };
    });

    this.props.onSubmit(formattedTickets, action);
  };

  getAction() {
    const el = document.activeElement;
    return (el && el.type === 'submit' && el.name) || null;
  }

  canSubmit() {
    const { bundle } = this.props;
    if (!bundle) return false;

    const { rollerProducts } = bundle;

    const { quantities, tickets, errors, noAvailability } = this.state;

    if (quantities < 1) return false;

    if (
      !tickets?.length ||
      !rollerProducts?.length ||
      tickets.length < rollerProducts.length
    )
      return false;

    let canSubmit = true;

    for (const product in errors) {
      if (errors[product]) {
        canSubmit = false;
        return;
      }
    }

    if (!canSubmit) return false;

    for (const product in noAvailability) {
      if (noAvailability[product]) {
        canSubmit = false;
        return;
      }
    }

    canSubmit = this.validateSession(rollerProducts);

    return canSubmit;
  }

  renderTotals(amount) {
    const { quantities } = this.state;

    if (amount) {
      const { totalAmountWithoutTaxes, totalTaxes } = amount;

      return (
        <div className={this.getElementClass('totals-container')}>
          <div className={this.getElementClass('totals-title')}>Summary</div>
          <Totals
            count={quantities}
            subtotal={totalAmountWithoutTaxes * quantities * 100}
            tax={totalTaxes * quantities * 100}
          />
        </div>
      );
    }

    return null;
  }

  errorTemplate(key, message) {
    return (
      <div
        className={this.getElementClass('error-container')}
        key={`error-${key}`}>
        <Icon size="tiny" name="info" />
        <div className={this.getElementClass('error-message')}>{message}</div>
      </div>
    );
  }

  renderErrors(capacity) {
    if (!capacity) {
      return this.errorTemplate('capacity', CAPACITY_ERROR_MESSAGE);
    }
    const { errors } = this.state;
    const errorsToRender = [];

    for (const product in errors) {
      if (errors[product]) {
        errorsToRender.push(this.errorTemplate(product, errors[product]));
      }
    }

    if (!errorsToRender?.length) return null;

    return <div>{errorsToRender}</div>;
  }

  getDateSelector(
    bundle,
    title,
    date,
    unavailableDates,
    calendarStyle,
    onDayChange,
    key = '',
    rollerProduct = null
  ) {
    const { rollerProducts, startDate, endDate } = bundle;
    const { quantities, reservationDates, tickets } = this.state;
    const today = new Date();
    let limitStartDate = startDate;

    if (DateTime.fromISO(endDate) >= DateTime.local()) {
      limitStartDate =
        DateTime.fromISO(startDate) < DateTime.local() ? today : startDate;
    }

    if (rollerProduct?.hiddenDatePicker) {
      return null;
    }

    return (
      <div className={this.getElementClass('date-container')} key={key}>
        <div className={this.getElementClass('date-title')}>{title}</div>
        <div className={this.getElementClass(calendarStyle)}>
          <DatePicker
            icon={<Icon size="tiny" name="calendar" />}
            date={date}
            startDate={parseApiDate(limitStartDate)}
            endDate={parseApiDate(endDate)}
            onDayChange={(reservationDate) =>
              onDayChange(reservationDate, rollerProduct || rollerProducts)
            }
            unavailabledates={unavailableDates}
          />
        </div>
        {rollerProduct?.hasSessions && (
          <SessionSelector
            rollerProduct={rollerProduct}
            date={reservationDates[rollerProduct.productId]}
            quantities={quantities}
            tickets={tickets}
            onSessionSelected={this.onSessionSelected}
          />
        )}
      </div>
    );
  }

  getPassportUnavailableDates(rollerProducts) {
    return rollerProducts
      .map((rollerProduct) =>
        this.props.bundles.unavailabilityDates.get(rollerProduct.productId)
      )
      .flat();
  }

  onSessionSelected = (productId, session) => {
    const { tickets } = this.state;
    const newTickets = tickets.map((ticket) => {
      if (ticket.productId === productId) {
        return {
          ...ticket,
          startTime: session.startTime,
          sessionProductId: session.productId,
        };
      }
      return ticket;
    });
    this.setState({ tickets: newTickets });
  };

  validateSession(rollerProducts) {
    const { tickets } = this.state;

    const needSessionProducts = rollerProducts.filter(
      ({ hasSessions }) => hasSessions
    );

    if (!needSessionProducts.length) return true;

    return tickets.every((ticket) => {
      const rollerProduct = needSessionProducts.find(
        (rollerProduct) => rollerProduct.productId === ticket.productId
      );
      if (!rollerProduct) return true;
      if (!ticket.sessionProductId || !ticket.startTime) return false;
      return true;
    });
  }

  renderDatesSelector(bundle, availability) {
    const { rollerProducts } = bundle;
    const { reservationDates, noAvailability } = this.state;

    const prods = rollerProducts
      ? rollerProducts.filter((a) => !a.hiddenDatePicker)
      : [];

    return prods.map((rollerProduct, index) => {
      const { name, productId } = rollerProduct;
      const hasErrorClass =
        (availability.hasOwnProperty(`${productId}`) &&
          !availability[`${productId}`]) ||
        noAvailability[`${productId}`];
      const unavailableDates =
        this.props.bundles.unavailabilityDates.get(productId);
      const calendarStyle = `date-calendar${hasErrorClass ? ' error' : ''}`;

      return this.getDateSelector(
        bundle,
        `Date for ${name}`,
        reservationDates && reservationDates[`${productId}`],
        unavailableDates,
        calendarStyle,
        this.onDayChange,
        index,
        rollerProduct
      );
    });
  }

  renderPassportDateSelector(bundle) {
    const { rollerProducts } = bundle;
    const { reservationDates, errors } = this.state;

    const calendarStyle = `date-calendar ${
      Object.values(errors).some((error) => error !== null) ? 'error' : ''
    }`;
    const unavailableDates = this.getPassportUnavailableDates(rollerProducts);

    return this.getDateSelector(
      bundle,
      'Initial date',
      reservationDates && Object.values(reservationDates)[0],
      unavailableDates,
      calendarStyle,
      this.onPassportDayChange
    );
  }

  renderSummary(amount, capacity, canSubmit) {
    return (
      <>
        {this.renderTotals(amount)}
        {this.renderErrors(capacity)}
        <FormActions>
          {this.props.renderActions(canSubmit, capacity)}
        </FormActions>
      </>
    );
  }

  render() {
    const { bundle } = this.props;
    const { quantities } = this.state;
    const availability = this.existAvailability(quantities);
    const { capacity } = availability;
    if (!bundle) {
      return (
        <React.Fragment>
          <Spacer size="l" />
          <Loader size="large" inline="centered" active />
          <Spacer size="l" />
        </React.Fragment>
      );
    }

    const { title, description, subDescription, image, amount, passportType } =
      bundle;

    let imageAlt, desktopSrc, mobileSrc;

    if (image) {
      imageAlt = image.imageAlt;
      desktopSrc = image.desktopSrc;
      mobileSrc = image.mobileSrc;
    }

    const canSubmit = this.canSubmit();

    return (
      <div {...this.getAttrs()}>
        <Form
          onSubmit={() => this.onSubmit()}
          className={this.getElementClass('form')}>
          <Layout
            horizontal
            stackable
            className={this.getElementClass('layout')}>
            <Layout.Group className={this.getElementClass('tickets')}>
              {imageAlt && mobileSrc && (
                <MobileOnly>
                  <ResponsiveImageLight src={mobileSrc} alt={imageAlt} fluid />
                </MobileOnly>
              )}
              {imageAlt && mobileSrc && (
                <TabletOnly className={this.getElementClass('header-image')}>
                  <ResponsiveImageLight src={mobileSrc} alt={imageAlt} fluid />
                </TabletOnly>
              )}
              <div className={this.getElementClass('container')}>
                <div className={this.getElementClass('header')}>
                  <div className={this.getElementClass('header-content')}>
                    <div className={this.getElementClass('title')}>{title}</div>
                    {description && (
                      <ContentfulRichText
                        className={this.getElementClass('description')}
                        field={description}
                      />
                    )}
                  </div>
                  {imageAlt && desktopSrc && (
                    <DesktopOnly
                      className={this.getElementClass('header-image')}>
                      <ResponsiveImageLight
                        src={desktopSrc}
                        alt={imageAlt}
                        fluid
                      />
                    </DesktopOnly>
                  )}
                </div>

                <SectionList>
                  <SectionItem title="Select dates for each park">
                    <div
                      className={this.getElementClass(
                        'dates-selector-container'
                      )}>
                      {!passportType
                        ? this.renderDatesSelector(bundle, availability)
                        : this.renderPassportDateSelector(bundle, availability)}
                    </div>
                  </SectionItem>
                  <SectionItem
                    title="Select how many tickets"
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <div className={this.getElementClass('item-description')}>
                      <div className={this.getElementClass('item-name')}>
                        {title}
                      </div>
                      <div className={this.getElementClass('item-price')}>
                        {amount ? `$${amount.totalAmountWithoutTaxes}` : ''}
                      </div>
                    </div>
                    <NumericUpDown
                      value={quantities}
                      min={0}
                      max={999}
                      onChange={(quantity) => this.setQuantity(quantity)}
                    />
                  </SectionItem>
                </SectionList>
                <MobileOnly>
                  {this.renderSummary(amount, capacity, canSubmit)}
                </MobileOnly>
                {subDescription && (
                  <ContentfulRichText
                    className={this.getElementClass('sub-description')}
                    field={subDescription}
                  />
                )}
              </div>
            </Layout.Group>
            <Layout.Group className={this.getElementClass('totals')}>
              <TabletOnly>
                {this.renderSummary(amount, capacity, canSubmit)}
              </TabletOnly>
              <DesktopOnly>
                {this.renderSummary(amount, capacity, canSubmit)}
              </DesktopOnly>
            </Layout.Group>
          </Layout>
        </Form>
      </div>
    );
  }
}

BundleSelector.propTypes = {
  bundle: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  renderActions: PropTypes.func.isRequired,
};
