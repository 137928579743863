import { observable, action } from 'mobx';
import { DateTime } from 'luxon';

import { request } from 'utils/api';
import BaseStore from 'common/stores/BaseStore';

export default class AdEventsStore extends BaseStore {
  @observable redirect = false;
  @observable eventsBlueprint = {};

  @action
  setRedirect() {
    this.redirect = true;
  }

  @action
  async getEventsSubCategories(query = '', statusKey = 'eventsSubCategories') {
    const status = this.createStatus(statusKey);
    const [events] = await this.getAllEvents({
      subCategory: 'all-events',
      page: -1,
      skip: -1,
      query,
      published: true,
    });
    const createRoute = (str) =>
      str
        .replaceAll(/[^0-9a-z\s+]/gi, '')
        .replace(/ +/g, '-')
        .trim()
        .toLowerCase();
    let subCategories = events
      .flatMap((event) => event.categories.map((category) => category.name))
      .filter((category) => category);
    subCategories = [...new Set(subCategories)].map((category) => {
      return {
        name: category,
        route: createRoute(category),
      };
    });
    status.success();
    return [
      { name: 'All Events', route: createRoute('All Events') },
      ...subCategories,
    ];
  }

  @action
  async getEventsBlueprint(statusKey = 'getEventsBlueprint') {
    if (Object.keys(this.eventsBlueprint).length) {
      return this.eventsBlueprint;
    }
    const status = this.createStatus(statusKey);
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/externalobjects/search',
        body: {
          objectType: 'eventsBlueprint',
        },
      });
      status.success();
      this.eventsBlueprint = data;
      return this.eventsBlueprint;
    } catch (err) {
      status.error(err);
      return err;
    }
  }

  @action
  getAllEvents(
    params = {
      page: -1,
      skip: -1,
      query: '',
      subCategory: 'all-events',
      published: false,
    },
    statusKey = 'listAllEvents'
  ) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/adEvents/filter`,
      body: {
        page: params.page,
        skip: params.skip,
        query: params.query,
        subCategory: params.subCategory,
        published: params.published,
      },
    })
      .then(({ data, totalItems }) => {
        status.success();
        return [
          data.map((event) => {
            return {
              ...event,
              schedules: event.schedules.map((a) => ({
                ...a,
                startDate: DateTime.fromFormat(a.startDate, 'yyyy-MM-dd', {
                  zone: 'America/New_York',
                }),
                endDate: DateTime.fromFormat(a.endDate, 'yyyy-MM-dd', {
                  zone: 'America/New_York',
                }),
              })),
            };
          }),
          totalItems,
        ];
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  getFeaturedEvents(statusKey = 'featuredEvents') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/adEvents/featured`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  filterEvents(query, statusKey = 'listAllEvents') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/adEvents/filter`,
      body: {
        page: -1,
        skip: -1,
        subCategory: 'all-events',
        query,
      },
    })
      .then(({ data, totalItems }) => {
        status.success();
        return [data, totalItems];
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
  @action
  filterArchivedEvents(query, statusKey = 'listAllArchivedEvents') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/adEvents/filter-archived-events`,
      body: { query },
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
  @action
  getArchivedEvents(statusKey = 'archivedEvents') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/adEvents/archived-events`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
  @action
  storeEvent(body, statusKey = 'store') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: `/1/adevents`,
      body,
    })
      .then(() => {
        status.success();
        this.setRedirect();
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  deleteEvent(eventId, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/adevents/${eventId}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  updateEvent({ eventId, body }, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/adevents/${eventId}`,
      body: { ...body },
    })
      .then(({ data }) => {
        status.success();
        this.setRedirect();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  updateEventStatus({ eventId, body }, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'PATCH',
      path: `/1/adevents/status/${eventId}`,
      body: { ...body },
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }

  @action
  getEventById(eventId, statusKey = 'getEventById') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/adevents/find/${eventId}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
  @action
  getEventBySlug(slug, statusKey = 'getEventBySlug') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/adevents/findBySlug/${slug}`,
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        return err;
      });
  }
}
