import { observable, action } from 'mobx';
import { DateTime } from 'luxon';

import { request, formatApiDate } from 'utils/api';

import BaseStore from './BaseStore';

export default class VenuesStore extends BaseStore {
  @observable register = new Map();
  @observable items = [];
  @observable totalItems = 0;
  @observable limit = 20;
  @observable page = 1;
  @observable categories = [];
  @observable sort = {
    order: 'asc',
    field: 'name',
  };

  @action
  setSort({ field, order }) {
    this.sort = {
      field,
      order,
    };
  }

  @action
  setPage(page) {
    this.page = page;
  }

  get(id) {
    return this.register.get(id);
  }

  getByCategory(venueType) {
    return Array.from(this.register)
      .map(([, venue]) => venue)
      .filter((venue) => venue.venueType === venueType);
  }

  parseItem(item) {
    return {
      ...item,
      updatedAt: new Date(item.updatedAt),
      createdAt: new Date(item.createdAt),
    };
  }

  @action
  fetchItem(id, date = new Date(), statusKey = `item:${id}`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/venues/${id}?date=${formatApiDate(date) || ''}`,
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set(item.id, item);
        status.success();
        return item;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  getAllOpenHours(statusKey = 'getAllOpenHours') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/venues/allopenhours',
    })
      .then(({ data }) => {
        status.success();
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchItemBySlug(slug, date = new Date(), statusKey = `slug:${slug}`) {
    const status = this.createStatus(statusKey);
    const numDays = 14;
    return request({
      method: 'GET',
      path: `/1/venues/slug/${slug}?date=${
        formatApiDate(date) || ''
      }&numDays=${numDays}`,
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set(item.slug, item);
        status.success();
        return item;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchVenuesByType(type, statusKey = `type:${type}`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/venues/type/${type}`,
    })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchVenuesByCategory(type, statusKey = `fetchVenuesByCategory`) {
    const inventory = this.getByCategory(type);
    if (inventory?.length > 0) {
      return Promise.resolve(inventory);
    }

    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/venues/category/${type}`,
    })
      .then(({ data }) => {
        const items = data.map((item) => ({
          ...this.parseItem(item),
          id: item._id,
        }));
        for (const item of items) {
          this.register.set(item.id, item);
        }

        return items;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchItems(
    {
      limit = this.limit,
      date = DateTime.local().toISODate(),
      skip = (this.page - 1) * this.limit,
      isListed = false,
      displayHours = false,
      hasTickets,
      allowBookingSwap,
      name,
      venueType,
      featuredFlags,
      jibestreamDestinationId,
      contentfulVenueId,
      categorySelected = 'all',
      subCategoryName = '',
    } = {},
    statusKey = 'list'
  ) {
    this.register.clear();
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/venues/search',
      body: {
        limit,
        skip,
        date,
        name,
        venueType,
        isListed,
        displayHours,
        hasTickets,
        allowBookingSwap,
        featuredFlags,
        jibestreamDestinationId,
        contentfulVenueId,
        sort: this.sort,
        subCategoryName,
      },
    })
      .then(({ data, meta }) => {
        const settedFeaturedSubCategories = ['now-open', 'coming-soon'];
        if (
          categorySelected.indexOf('all') === -1 &&
          !settedFeaturedSubCategories.includes(categorySelected)
        ) {
          const currentCategory = this.categories.find(
            (category) => category.route === categorySelected
          );
          data = data.filter((item) =>
            item.categories.includes(currentCategory.name)
          );
        }
        if (settedFeaturedSubCategories.includes(categorySelected)) {
          const currentCategory = this.categories.find(
            (category) => category.route === categorySelected
          );
          data = data.filter((item) =>
            item.featuredFlags.includes(currentCategory.name)
          );
        }
        const items = data.map((item) => this.parseItem(item));
        items.forEach((item) => {
          this.register.set(item.id, item);
        });
        this.totalItems = meta.total;
        this.items.replace(items);
        status.success();
        return [items, meta.total];
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchCategories(_, statusKey = 'categories') {
    if (this.categories.length) {
      return Promise.resolve(this.categories);
    }

    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: '/1/metadata/category',
    })
      .then(({ data }) => {
        const createRoute = (str) =>
          str
            .replaceAll(/[^0-9a-z\s+]/gi, '')
            .replace(/ +/g, '-')
            .trim()
            .toLowerCase();
        const defaultCategories = [
          {
            categoryType: 'attraction',
            name: 'All Entertainment',
            route: createRoute('All Entertainments'),
            order: 0,
          },
          {
            categoryType: 'shopping',
            name: 'All Stores',
            route: createRoute('All Stores'),
            order: 0,
          },
          {
            categoryType: 'restaurant',
            name: 'All Food & Drink',
            route: createRoute('All Food & Drink'),
            order: 0,
          },
        ];
        const finalCategories = data.map((category) => {
          return {
            categoryType: category.type,
            name: category.name,
            order: category.order,
            route: createRoute(category.name),
          };
        });
        this.categories.replace([...defaultCategories, ...finalCategories]);
        status.success();
        return [...defaultCategories, ...finalCategories];
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  create(body, statusKey = 'create') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'POST',
      path: '/1/venues',
      body,
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set(item.id, item);

        return item;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  update(body, statusKey = 'update') {
    const status = this.createStatus(statusKey);
    const { id, ...rest } = body;
    return request({
      method: 'PATCH',
      path: `/1/venues/${id}`,
      body: rest,
    })
      .then(({ data }) => {
        const item = this.parseItem(data);
        this.register.set(item.id, item);
        return item;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  delete(item, statusKey = 'delete') {
    const status = this.createStatus(statusKey);
    return request({
      method: 'DELETE',
      path: `/1/venues/${item.id}`,
    })
      .then(() => {
        this.register.delete(item.id);
        return this.fetchItems({}, false).then(() => {
          status.success();
          return item;
        });
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  fetchExternalBookingsVenues(statusKey = `externalBookingsVenues`) {
    const status = this.createStatus(statusKey);
    return request({
      method: 'GET',
      path: `/1/venues/externalbookings`,
    })
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        status.error(err);
        throw err;
      });
  }

  @action
  generateKeywords(venueId, prompt) {
    return request({
      method: 'POST',
      path: `/1/venues/${venueId}/generate-keywords`,
      body: {
        prompt,
      },
    })
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  }
}
