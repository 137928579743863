import React from 'react';
import { useClassName, useBannerVisibility } from 'common/hooks';
import { ContentfulRichText } from 'common/components';
import { ScrollDownArrow, LogoOverlay } from './HeroOverlays';
import useCustomStyle from '../../hooks';
import HomepageAsset from 'public/screens/Home/Components/Asset';
import Actions from '../Actions';
import Link from '../Link';

import './hero.less';

export default function Hero({ component }) {
  const classNames = useClassName('CLPHero');
  const isBannerVisible = useBannerVisibility();

  const [, styles] = useCustomStyle(component.fields.settings);

  const withOverlay = Boolean(
    component?.fields?.title ||
      component.fields.title ||
      component?.fields?.links?.length
  );
  const useFullScreen = styles['--full-screen-hero'];
  const logoOverlay = component?.fields?.logoOverlay;

  return (
    <div className={classNames(['container', withOverlay && 'overlay'])}>
      <HomepageAsset
        asset={component.fields.asset}
        className={classNames('media')}
        style={{
          opacity: 1,
          aspectRatio: useFullScreen ? 'unset' : undefined,
          height: useFullScreen
            ? `calc(100vh - 60px - ${isBannerVisible ? '34px' : '0px'})`
            : undefined,
        }}
      />
      <ScrollDownArrow useFullScreen={useFullScreen} />
      <LogoOverlay logo={logoOverlay} />

      {withOverlay && (
        <div className={classNames('content')}>
          {Boolean(component?.fields?.title) && (
            <h1>{component.fields.title}</h1>
          )}
          {Boolean(component?.fields?.description) && (
            <h2>
              <ContentfulRichText
                field={{ 'en-US': component.fields.description }}
              />
            </h2>
          )}
          {Boolean(component?.fields?.links?.length) && (
            <Actions>
              {component.fields.links.map((link) => (
                <Link key={link.sys.id} component={link} />
              ))}
            </Actions>
          )}
        </div>
      )}
    </div>
  );
}
