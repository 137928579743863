import React from 'react';
import { SVGIcon } from 'common/components';
import { useClassName, useScrollY } from 'common/hooks';
import './hero-overlays.less';

const classNames = useClassName('CLPHeroOverlays');

const ScrollDownArrow = ({ useFullScreen }) => {
  if (!useFullScreen) return null;
  const { scrollY } = useScrollY();
  const isVisible = scrollY < 100;
  return (
    <SVGIcon
      name="scroll-down-arrow"
      width={46}
      height={46}
      className={classNames('scroll-down-arrow', !isVisible ? 'hidden' : '')}
    />
  );
};

const LogoOverlay = ({ logo }) => {
  if (!logo) return null;
  return (
    <div className={classNames('logo-overlay')}>
      <img
        src={logo.fields.file.url}
        className={classNames('logo')}
        alt={logo.fields.file.details?.description || 'Logo'}
      />
    </div>
  );
};

export { ScrollDownArrow, LogoOverlay };
