import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const useOutsideClick = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

const Dropdown = ({
  isOpen,
  onClose,
  content,
  useTriggerWidth,
  watchEdges,
}) => {
  const ref = useRef();
  const dropdownRef = useRef();
  useOutsideClick(dropdownRef, onClose);

  const showContent = () => {
    if (!ref.current) return null;

    const triggerRect = ref.current.getBoundingClientRect();
    const top = triggerRect.bottom + window.scrollY + 10;
    const left = triggerRect.left;

    const element = (
      <div
        ref={dropdownRef}
        style={{
          position: 'absolute',
          zIndex: 9999999,
          top,
          left,
          ...(useTriggerWidth && { width: ref.current.clientWidth }),
        }}>
        {content}
      </div>
    );

    return createPortal(element, document.getElementById('root'));
  };

  useEffect(() => {
    if (isOpen && watchEdges && dropdownRef.current && ref.current) {
      const checkEdges = () => {
        const windowWidth = window.innerWidth;
        const dropdownRect = dropdownRef.current.getBoundingClientRect();

        const rightEdge = dropdownRect.left + dropdownRect.width;
        const safeWidth = windowWidth - 50;

        if (rightEdge > safeWidth) {
          const newLeft = Math.max(0, safeWidth - dropdownRect.width);
          dropdownRef.current.style.left = `${newLeft}px`;
        }
      };

      setTimeout(checkEdges, 0);
    }
  }, [isOpen, watchEdges]);

  return <div ref={ref}>{isOpen && showContent()}</div>;
};

export default Dropdown;
